.MapBuilder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.MapBuilder-options {
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  width: 250px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
}

.MapBuilder-options .MuiFormControl-root {
  display: flex;
}

.MapBuilder-extent {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 2px;
}

.MapBuilder-extent-label {
}

.MapBuilder-extent-value {
  margin-left: auto;
  font-family: monospace;
}

.MapBuilder-leftright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 32px;
}

.MapBuilder-leftright-right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.MapBuilder-color-chip {
  margin: 0 4px;
  width: 20px;
  height: 20px;
  outline: 1px solid #ccc;
  opacity: .2;
  cursor: pointer;
}

.MapBuilder-color-chip.selected {
  opacity: 1;
}

.MapBuilder-color-chip:hover {
  opacity: 1;
}