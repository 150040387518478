.Map-outer-container {
  width: 100vw;
  height: calc(100vh - 64px);
  display: block;
  position: relative;
}

.Map-container {
  width: calc(100vw - 250px);
  height: 100%;
  position: relative;
}

.Map-overlay {
  position: absolute;
  pointer-events: none;
  border: 1px solid black;
  box-sizing: border-box;
}

.Map-bbox-bearing {
  position: absolute;
  pointer-events: none;
  border: 1px solid blue;
}

.Map-safezone {
  position: absolute;
  pointer-events: none;
  border: 1px dashed red;
  box-sizing: border-box;
}

.Map-safezone-border {
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
}

.Map-preview-ocean {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #603913;
}

.Map-exportbutton {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
}